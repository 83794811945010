.nav-label {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 10;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ast-global-color-1);
  border-radius: 500px;
  cursor: pointer;
}
.nav-label:hover, .nav-label:active, .nav-label:focus {
  background-color: var(--ast-global-color-8);
}
.nav-label__line {
  background-color: var(--ast-global-color-4);
  position: relative;
}
.nav-label__line, .nav-label__line::before, .nav-label__line::after {
  width: 1.5rem;
  height: 1px;
  left: 0;
  background-color: var(--ast-global-color-5);
}
.nav-label__line::before {
  content: "";
  position: absolute;
  top: 6px;
}
.nav-label__line::after {
  content: "";
  position: absolute;
  bottom: 6px;
}
.nav-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  z-index: 9;
  box-shadow: var(--shadow-3d);
  background-color: rgb(79, 108, 255);
  letter-spacing: 0.1rem;
  background-image: url("https://www.transparenttextures.com/patterns/inspiration-geometry.png");
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 880px) {
  .nav-container {
    width: 50%;
  }
}
@media (max-width: 672px) {
  .nav-container {
    width: 100%;
  }
}
.nav-ul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: column;
}
.nav-li {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nav-li a {
  padding: 0.25rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  color: var(--ast-global-color-5);
}
.nav-li a::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.25);
  width: 100%;
  height: 1px;
}
.nav-li a:hover::after {
  background-color: rgba(255, 255, 255, 0.5);
}
.nav-li a:active::after, .nav-li a:focus::after {
  background-color: rgba(255, 255, 255, 0.75);
}
.nav-li__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.nav-li__title {
  padding: 0 1rem;
}
.nav-open .nav-label__line {
  width: 0;
}
.nav-open .nav-label__line::before {
  transform: translate(-0.75rem, -6px) rotate(-45deg);
}
.nav-open .nav-label__line::after {
  transform: translate(-0.75rem, 6px) rotate(45deg);
}
.nav-cover {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  transition: none;
}
.nav-open .nav-cover {
  width: 100vw;
  height: 100vh;
  background-color: var(--back-shadow);
}

/*# sourceMappingURL=navigation.css.map */
