button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  user-select: none;
  font: inherit;
  color: inherit;
}

.btn {
  width: fit-content;
  height: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.875rem 1.35rem;
  letter-spacing: 0.05rem;
  background-color: var(--blue);
  color: var(--white);
  white-space: nowrap;
  font-family: "Gilroy-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  box-shadow: var(--shadow-elevation-2dp);
}
.btn-lg {
  padding: 1.1rem 1.75rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.btn:hover {
  box-shadow: var(--shadow-elevation-4dp);
}
.btn:active {
  box-shadow: var(--shadow-elevation-2dp);
}
.btn .material-symbols-outlined {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}
.btn-blue {
  background-color: var(--blue);
  outline: 2px solid var(--blue);
}
.btn-blue.btn-outline {
  outline: 2px solid var(--blue);
  color: var(--blue);
}
.btn-red {
  background-color: var(--red);
  outline: 2px solid var(--red);
}
.btn-red.btn-outline {
  outline: 2px solid var(--red);
  color: var(--red);
}
.btn-yellow {
  background-color: var(--yellow);
  outline: 2px solid var(--yellow);
}
.btn-yellow.btn-outline {
  outline: 2px solid var(--yellow);
  color: var(--yellow);
}
.btn-green {
  background-color: var(--green);
  outline: 2px solid var(--green);
}
.btn-green.btn-outline {
  outline: 2px solid var(--green);
  color: var(--green);
}
.btn-white {
  background-color: var(--white);
  outline: 2px solid var(--white);
}
.btn-white.btn-outline {
  outline: 2px solid var(--white);
  color: var(--white);
}
.btn-black {
  background-color: var(--black);
  outline: 2px solid var(--black);
}
.btn-black.btn-outline {
  outline: 2px solid var(--black);
  color: var(--black);
}
.btn-purple {
  background-color: var(--purple);
  outline: 2px solid var(--purple);
}
.btn-purple.btn-outline {
  outline: 2px solid var(--purple);
  color: var(--purple);
}
.btn-indigo {
  background-color: var(--indigo);
  outline: 2px solid var(--indigo);
}
.btn-indigo.btn-outline {
  outline: 2px solid var(--indigo);
  color: var(--indigo);
}
.btn-orange {
  background-color: var(--orange);
  outline: 2px solid var(--orange);
}
.btn-orange.btn-outline {
  outline: 2px solid var(--orange);
  color: var(--orange);
}
.btn-grey {
  background-color: var(--grey);
  outline: 2px solid var(--grey);
}
.btn-grey.btn-outline {
  outline: 2px solid var(--grey);
  color: var(--grey);
}
.btn-brown {
  background-color: var(--brown);
  outline: 2px solid var(--brown);
}
.btn-brown.btn-outline {
  outline: 2px solid var(--brown);
  color: var(--brown);
}
.btn-light-blue {
  background-color: var(--light-blue-100);
  color: var(--black);
  outline: 2px solid var(--light-blue-100);
}
.btn-light-blue.btn-outline {
  outline: 2px solid var(--light-blue-100);
  color: var(--white);
}
.btn-outline {
  background-color: transparent;
}
.btn-white {
  color: var(--black);
}
.btn-white.btn-outline {
  outline: 2px solid var(--black);
  color: var(--black);
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  padding: 1rem;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--tcolor);
}
.icon .material-symbols-outlined {
  font-size: 1.5rem;
}
.icon:hover {
  background-color: var(--back-shadow-light);
}

/*# sourceMappingURL=button.css.map */
