@charset "UTF-8";
.about-faq-li {
  margin: 1rem auto;
  width: fit-content;
}
.about-faq-hr {
  width: 100%;
  border: none;
  height: 1px;
  margin: 0.5rem 0 0 0;
  background-image: linear-gradient(to right, #f0f0f0, #8f8f8f, #f0f0f0);
}
.about-faq-hr::after {
  content: "§";
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%) rotate(60deg);
  transform-origin: 50% 50%;
  padding: 1rem;
}

/*# sourceMappingURL=about.css.map */
