*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;
  scroll-behavior: smooth;
}

:root,
:root::before,
:root::after {
  --bgcolor: #f0f0f0;
  --bgcolor-100: #e1e1e1;
  --bgcolor-400: #d2d2d2;
  --bgcolor-700: #c3c3c3;
  --tcolor: #121212;
  --tcolor-100: #333333;
  --tcolor-400: #272727;
  --tcolor-700: #1d1d1d;
  --scroll-100: #e1e1e1;
  --scroll-400: #4a4a4a;
  --scroll-700: #272727;
  --primary-1: #0540f2;
  --primary-2: #0583f2;
  --primary-3: #575959;
  --primary-4: #f2600c;
  --primary-5: #0d0d0d;
  --ast-global-color-0: #0170b9;
  --ast-global-color-1: #3a3a3a;
  --ast-global-color-2: #3a3a3a;
  --ast-global-color-3: #4b4f58;
  --ast-global-color-4: #f5f5f5;
  --ast-global-color-5: #ffffff;
  --ast-global-color-6: #f2f5f7;
  --ast-global-color-7: #424242;
  --ast-global-color-8: #000000;
  --white: #ffffff;
  --black: #000000;
  --head-height: 6rem;
  --back-shadow: rgba(0, 0, 0, 0.2);
  --back-shadow-light: rgba(0, 0, 0, 0.1);
  --back-shadow-dark: rgba(0, 0, 0, 0.25);
  --transition-time: 200ms;
  --shadow-transition: 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  --shadow-elevation-2dp: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  	0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  --shadow-elevation-4dp: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
  	0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
  --shadow-elevation-6dp: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
  	0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
  --shadow-elevation-8dp: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
  	0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4);
  --shadow-elevation-12dp: 0 12px 16px 1px rgba(0, 0, 0, 0.14),
  	0 4px 22px 3px rgba(0, 0, 0, 0.12), 0 6px 7px -4px rgba(0, 0, 0, 0.4);
  --shadow-elevation-16dp: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
  	0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
  --shadow-elevation-24dp: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
  	0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.4);
  --font-regular: "Gilroy-Regular", Cabin, -apple-system, BlinkMacSystemFont,
  	"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  	"Helvetica Neue", sans-serif;
  --font-bold: "Gilroy-Bold", Cabin, -apple-system, BlinkMacSystemFont,
  	"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  	"Helvetica Neue", sans-serif;
  --font-light: "Gilroy-Light", Cabin, -apple-system, BlinkMacSystemFont,
  	"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  	"Helvetica Neue", sans-serif;
  --font-medium: "Gilroy-Medium", Cabin, -apple-system, BlinkMacSystemFont,
  	"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  	"Helvetica Neue", sans-serif;
  --font-thin: "Gilroy-Thin", Cabin, -apple-system, BlinkMacSystemFont,
  	"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  	"Helvetica Neue", sans-serif;
  --red: #f44336;
  --red-100: #ffcdd2;
  --red-400: #ef5350;
  --red-700: #d32f2f;
  --pink: #e91e63;
  --pink-100: #f8bbd0;
  --pink-400: #ec407a;
  --pink-700: #c2185b;
  --purple: #9c27b0;
  --purple-100: #e1bee7;
  --purple-400: #ab47bc;
  --purple-700: #7b1fa2;
  --dark-purple: #673ab7;
  --dark-purple-100: #d1c4e9;
  --dark-purple-400: #7e57c2;
  --dark-purple-700: #512da8;
  --indigo: #3f51b5;
  --indigo-100: #c5cae9;
  --indigo-400: #5c6bc0;
  --indigo-700: #303f9f;
  --blue: #2196f3;
  --blue-100: #bbdefb;
  --blue-400: #42a5f5;
  --blue-700: #1976d2;
  --light-blue: #03a9f4;
  --light-blue-100: #b3e5fc;
  --light-blue-400: #29b6f6;
  --light-blue-700: #0288d1;
  --cyan: #00bcd4;
  --cyan-100: #b2ebf2;
  --cyan-400: #26c6da;
  --cyan-700: #0097a7;
  --green: #4caf50;
  --green-100: #c8e6c9;
  --green-400: #66bb6a;
  --green-700: #388e3c;
  --light-green: #8bc34a;
  --light-green-100: #dcedc8;
  --light-green-400: #9ccc65;
  --light-green-700: #689f38;
  --yellow: #ffeb3b;
  --yellow-100: #fff9c4;
  --yellow-400: #ffee58;
  --yellow-700: #fbc02d;
  --orange: #ff9800;
  --orange-100: #ffe0b2;
  --orange-400: #ffa726;
  --orange-700: #f57c00;
  --deep-orange: #ff5722;
  --deep-orange-100: #ffccbc;
  --deep-orange-400: #ff7043;
  --deep-orange-700: #e64a19;
  --brown: #795548;
  --brown-100: #d7ccc8;
  --brown-400: #8d6e63;
  --brown-700: #5d4037;
  --grey: #9e9e9e;
  --grey-100: #f5f5f5;
  --grey-400: #bdbdbd;
  --grey-700: #616161;
  --blue-grey: #607d8b;
  --blue-grey-100: #cfd8dc;
  --blue-grey-400: #78909c;
  --blue-grey-700: #455a64;
  --blue: #4285f4;
  --red: #ea4335;
  --yellow: #fbbc05;
  --green: #34a853;
}

.row {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.row > * {
  width: 100%;
}

[class^=col] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (min-width: 880px) {
  .row .col-lg-100 {
    width: 100%;
  }
  .row .col-lg-95 {
    width: 95%;
  }
  .row .col-lg-90 {
    width: 90%;
  }
  .row .col-lg-85 {
    width: 85%;
  }
  .row .col-lg-83 {
    width: 83.333%;
  }
  .row .col-lg-80 {
    width: 80%;
  }
  .row .col-lg-75 {
    width: 75%;
  }
  .row .col-lg-70 {
    width: 70%;
  }
  .row .col-lg-66 {
    width: 66.666%;
  }
  .row .col-lg-60 {
    width: 60%;
  }
  .row .col-lg-50 {
    width: 50%;
  }
  .row .col-lg-45 {
    width: 45%;
  }
  .row .col-lg-40 {
    width: 40%;
  }
  .row .col-lg-33 {
    width: 33.333%;
  }
  .row .col-lg-30 {
    width: 30%;
  }
  .row .col-lg-25 {
    width: 25%;
  }
  .row .col-lg-20 {
    width: 20%;
  }
  .row .col-lg-16 {
    width: 16.666%;
  }
  .row .col-lg-15 {
    width: 15%;
  }
  .row .col-lg-10 {
    width: 10%;
  }
}
@media (min-width: 672px) and (max-width: 879.98px) {
  .row .col-md-100 {
    width: 100%;
  }
  .row .col-md-95 {
    width: 95%;
  }
  .row .col-md-90 {
    width: 90%;
  }
  .row .col-md-85 {
    width: 85%;
  }
  .row .col-md-83 {
    width: 83.333%;
  }
  .row .col-md-80 {
    width: 80%;
  }
  .row .col-md-75 {
    width: 75%;
  }
  .row .col-md-70 {
    width: 70%;
  }
  .row .col-md-66 {
    width: 66.666%;
  }
  .row .col-md-60 {
    width: 60%;
  }
  .row .col-md-50 {
    width: 50%;
  }
  .row .col-md-45 {
    width: 45%;
  }
  .row .col-md-40 {
    width: 40%;
  }
  .row .col-md-33 {
    width: 33.333%;
  }
  .row .col-md-30 {
    width: 30%;
  }
  .row .col-md-25 {
    width: 25%;
  }
  .row .col-md-20 {
    width: 20%;
  }
  .row .col-md-16 {
    width: 16.666%;
  }
  .row .col-md-15 {
    width: 15%;
  }
  .row .col-md-10 {
    width: 10%;
  }
}
@media (max-width: 671.98px) {
  .row .col-sm-100 {
    width: 100%;
  }
  .row .col-sm-95 {
    width: 95%;
  }
  .row .col-sm-90 {
    width: 90%;
  }
  .row .col-sm-85 {
    width: 85%;
  }
  .row .col-sm-83 {
    width: 83.333%;
  }
  .row .col-sm-80 {
    width: 80%;
  }
  .row .col-sm-75 {
    width: 75%;
  }
  .row .col-sm-70 {
    width: 70%;
  }
  .row .col-sm-66 {
    width: 66.666%;
  }
  .row .col-sm-60 {
    width: 60%;
  }
  .row .col-sm-50 {
    width: 50%;
  }
  .row .col-sm-45 {
    width: 45%;
  }
  .row .col-sm-40 {
    width: 40%;
  }
  .row .col-sm-33 {
    width: 33.333%;
  }
  .row .col-sm-30 {
    width: 30%;
  }
  .row .col-sm-25 {
    width: 25%;
  }
  .row .col-sm-20 {
    width: 20%;
  }
  .row .col-sm-16 {
    width: 16.666%;
  }
  .row .col-sm-15 {
    width: 15%;
  }
  .row .col-sm-10 {
    width: 10%;
  }
}
.responsive-masonry {
  list-style: none;
  column-gap: 0;
}
.responsive-masonry-box {
  width: 100%;
  height: auto;
  break-inside: avoid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  overflow: hidden;
}

.responsive-masonry-layout-1 {
  column-count: 1;
}

.responsive-masonry-layout-2 {
  column-count: 2;
}

.responsive-masonry-layout-3 {
  column-count: 3;
}

.responsive-masonry-layout-4 {
  column-count: 4;
}

.responsive-masonry-layout-5 {
  column-count: 5;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("./fonts/Gilroy-Bold.eot");
  src: url("./fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/Gilroy-Bold.woff") format("woff"), url("./fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Light";
  src: url("./fonts/Gilroy-Light.eot");
  src: url("./fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/Gilroy-Light.woff") format("woff"), url("./fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("./fonts/Gilroy-Medium.eot");
  src: url("./fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"), url("./fonts/Gilroy-Medium.woff") format("woff"), url("./fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Regular";
  src: url("./fonts/Gilroy-Regular.eot");
  src: url("./fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/Gilroy-Regular.woff") format("woff"), url("./fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Thin";
  src: url("./fonts/Gilroy-Thin.eot");
  src: url("./fonts/Gilroy-Thin.eot?#iefix") format("embedded-opentype"), url("./fonts/Gilroy-Thin.woff") format("woff"), url("./fonts/Gilroy-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  font-size: 16px;
}
@media (max-width: 880px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 672px) {
  html {
    font-size: 12px;
  }
}

body,
#root {
  font-family: var(--font-regular);
  font-size: 1rem;
  background-color: var(--bgcolor);
  color: var(--tcolor);
  min-height: 100vh;
  width: 100vw;
  display: flex;
  letter-spacing: 0.025rem;
  word-spacing: 0.25rem;
  line-height: 1.25rem;
  transition: all var(--transition-time) ease-in-out;
  overflow-x: hidden;
  position: relative;
}

#root {
  width: 100%;
  height: 100%;
  flex-flow: column;
  background: transparent;
}

main {
  width: 100%;
  min-height: calc(100vh - var(--head-height));
}

img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

svg {
  overflow: visible;
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover, a:active, a:focus, a:visited {
  border: none;
  outline: none;
}

button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  user-select: none;
  font: inherit;
}

input, input:hover, input:active, input:focus,
textarea,
textarea:hover,
textarea:active,
textarea:focus,
select,
select:hover,
select:active,
select:focus {
  background: transparent;
  font: inherit;
  border: none;
  outline: none;
  resize: none;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  user-select: none;
  font-size: 0.875rem;
  color: var(--gray-700);
}

a {
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  min-width: 0.7vw;
  max-width: 0.5vw;
  scroll-behavior: smooth;
  background-color: var(--primary-3);
  border: 4px solid transparent;
}
::-webkit-scrollbar-track {
  background-color: var(--primary-5);
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: var(--primary-1);
  border-radius: 500px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-4);
}

.material-symbols-outlined {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

/*# sourceMappingURL=style.css.map */
