.not-found {
  width: 100vw;
  height: 100vh;
  padding-top: var(--head-height);
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found-image {
  width: 50%;
  height: 100%;
  background-size: contain;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
}
.not-found-content {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.not-found-content h2 {
  font-size: 3rem;
  line-height: 3.5rem;
  margin: 2rem;
}
.not-found-content h3 {
  font-size: 1.75rem;
  line-height: 2.5rem;
  margin: 1rem;
}
.not-found-content-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found-content-buttons button {
  margin: 1rem;
}

/*# sourceMappingURL=not-found.css.map */
