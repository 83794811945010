.gallery {
  background-image: repeating-linear-gradient(to right, var(--bgcolor-700), var(--bgcolor-700) 1px, transparent 1px, transparent);
  background-size: 250px 250px;
}
.gallery-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--head-height);
  position: relative;
}
.gallery-hero::before, .gallery-hero::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: 25vw;
  background-color: #1d79f2;
  opacity: 0.3;
  filter: blur(220px);
  z-index: -1;
}
.gallery-hero::before {
  left: 25%;
}
.gallery-hero::after {
  left: 75%;
}
.gallery-hero-dots {
  position: absolute;
}
.gallery-hero-dots[data-index="1"] {
  top: 20rem;
  left: 5rem;
  width: 3rem;
  height: 3rem;
  transform: rotate(75deg);
}
.gallery-hero-dots[data-index="2"] {
  top: 10rem;
  right: 3rem;
  width: 2.4rem;
  height: 2.4rem;
  transform: rotate(45deg);
}
.gallery-hero-ellipse {
  position: absolute;
  top: 70%;
  right: 10%;
}
.gallery-hero-head {
  width: 100%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.gallery-hero-head span:first-child {
  font-size: 4rem;
  line-height: 4.5rem;
  font-family: var(--font-bold);
  color: var(--indigo-700);
}
.gallery-hero-body {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-body {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin-bottom: 2rem;
}
.gallery-body-img {
  margin: 1rem;
  overflow: hidden;
}
.gallery-body-img img {
  border-radius: 16px;
}

/*# sourceMappingURL=gallery.css.map */
