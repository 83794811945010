.header {
  width: 100%;
  height: calc(var(--head-height) + 1rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  position: relative;
  top: 0;
  left: 0;
  z-index: 5;
  color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0s none;
}
.header-left {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-left-image {
  width: var(--head-height);
  height: calc(var(--head-height) - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
}
.header-left-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}
.header-left-content__name {
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: var(--font-bold);
}
.header-right {
  height: 100%;
}
.header-nav {
  height: 100%;
}
.header-nav-ul {
  height: 100%;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.header-nav-ul li {
  padding: 1rem 0.5rem;
}
.header-nav-ul a {
  padding: 0.25rem 0.5rem;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.header-nav-ul a:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: var(--primary-1);
  height: 1px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.header-nav-ul a:hover:before {
  left: 0;
  right: 0;
}

/*# sourceMappingURL=header.css.map */
