.progothon {
  width: 100%;
  height: auto;
  position: relative;
}
.progothon > section {
  min-height: 100vh;
}
.progothon-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.5);
}
.progothon-hero-container {
  transform: translateY(2rem);
  color: var(--white);
}
.progothon-hero-container > * {
  margin: 1rem 0;
}
.progothon-hero-image {
  height: 30%;
}
.progothon-hero-image img {
  width: 35vh;
  height: 35vh;
}
.progothon-hero-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 672px) {
  .progothon-hero-btn {
    flex-flow: column;
  }
}
.progothon-hero-btn button {
  margin: 2rem 1rem;
  overflow: hidden;
}
.progothon-hero-btn button img {
  width: 24px;
}
@media (max-width: 672px) {
  .progothon-hero-btn button {
    margin: 1rem;
  }
}
.progothon-hero h1 {
  font-size: 3.5rem;
  line-height: 4rem;
  text-transform: uppercase;
}
.progothon-hero h3 {
  font-size: 2rem;
  line-height: 2.5rem;
}
.progothon-hero button {
  height: 2.75rem;
  background-color: transparent;
  outline: 2px solid var(--blue);
  border-radius: 4px;
  padding: 0 1.35rem;
  display: inline-flex;
  align-items: center;
  flex-flow: row;
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.progothon-hero button:first-child {
  background-color: var(--blue-700);
  outline-color: var(--blue-700);
}
@media (max-width: 672px) {
  .progothon-hero button {
    font-size: 1.5rem;
    height: 3.5rem;
  }
}
.progothon-hero button span {
  margin: 0 0.3rem;
}
.progothon-body {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.progothon-body-section {
  display: flex;
  flex-flow: column;
  height: 100vh;
}
.progothon-body-section li::marker {
  content: " > ";
}
.progothon-body-content {
  width: 60%;
  padding: 3rem;
}
.progothon-body-content h1 {
  font-size: 3rem;
  line-height: 3.5rem;
  margin: 1rem 0;
}
@media (max-width: 672px) {
  .progothon-body-content h1 {
    font-size: 3.5rem;
    line-height: 3.75rem;
  }
}
.progothon-body-content p {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 1rem 0;
}
@media (max-width: 672px) {
  .progothon-body-content p {
    font-size: 1.65rem;
    line-height: 2.25rem;
  }
}
.progothon-body-content li {
  margin: 0.5rem 0;
}
@media (max-width: 880px) {
  .progothon-body-content {
    width: 75%;
  }
}
@media (max-width: 672px) {
  .progothon-body-content {
    width: 100%;
    margin-top: 20vh;
  }
}
.progothon-body-schedule {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.progothon-body-schedule__day {
  font-size: 1.75rem;
  line-height: 2rem;
  margin: 0.5rem 0;
  font-family: var(--font-regular);
}
.progothon-body-schedule__date span:first-child {
  font-size: 5rem;
  line-height: 5.25rem;
  font-family: var(--font-bold);
  margin: 0.5rem 1rem 0.5rem 0;
}
.progothon-body-schedule__date span:last-child {
  font-size: 3.5rem;
  line-height: 3.75rem;
  font-family: var(--font-thin);
  margin: 0.5rem 0;
}
.progothon-body-schedule__time {
  font-size: 1.75rem;
  line-height: 2rem;
  margin: 0.5rem 0;
  font-family: var(--font-thin);
}
.progothon-body-schedule__p {
  width: 80%;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 1rem 0;
  height: 4rem;
}
.progothon-body-schedule-btns {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.progothon-body-schedule-btns button {
  border-radius: 500px;
  margin: 0 1.5rem 0 0;
  padding: 1rem;
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--brown);
  display: flex;
  justify-content: center;
  align-items: center;
}
.progothon-rewards {
  height: 60%;
}
.progothon-reward {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  flex-wrap: wrap;
}
@media (max-width: 672px) {
  .progothon-reward {
    margin: 0.5rem 0;
  }
}
.progothon-reward-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
@media (max-width: 672px) {
  .progothon-reward-content {
    width: 45%;
    align-items: flex-start;
    margin-left: 0.875rem;
  }
}
@media (max-width: 672px) {
  .progothon-reward {
    flex-flow: row;
  }
}
.progothon-reward img {
  width: 70%;
  height: auto;
}
@media (max-width: 672px) {
  .progothon-reward img {
    width: 55%;
  }
}
.progothon-reward h2 {
  margin: 1.125rem 0;
  white-space: nowrap;
}
@media (max-width: 672px) {
  .progothon-reward h2 {
    font-size: 1.75rem;
  }
}
.progothon-reward h4 {
  margin: 0 0 0.35rem 0;
}
@media (max-width: 672px) {
  .progothon-reward h4 {
    font-size: 1.35rem;
  }
}
.progothon-aside {
  width: 40%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 4.5rem;
  line-height: 5rem;
  padding: 3rem;
  font-family: var(--font-bold);
  color: var(--white);
  position: static;
  top: 0;
  left: 0;
  z-index: 5;
}
.progothon-aside-container {
  width: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-flow: column;
}
@media (max-width: 880px) {
  .progothon-aside-container {
    transform-origin: 0 0;
    transform: rotateZ(-90deg);
    flex-flow: row;
  }
}
@media (max-width: 672px) {
  .progothon-aside-container {
    transform: none;
    font-size: 3.75rem;
  }
}
.progothon-aside span:first-child {
  margin-right: 2rem;
}
@media (max-width: 880px) {
  .progothon-aside {
    width: 25%;
    align-items: flex-end;
  }
}
@media (max-width: 672px) {
  .progothon-aside {
    width: 100%;
    height: 20vh;
  }
}
.progothon-sponsors {
  width: 100%;
}
.progothon-sponsor {
  height: 10rem;
  width: 15rem;
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
}
.progothon-sponsor img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  transition: transform 400ms, filter 400ms;
}
.progothon-sponsor:is(:hover, :focus) {
  box-shadow: var(--shadow-elevation-4dp);
}
.progothon-sponsor:is(:hover, :focus) > img {
  transform: scale(1.1);
}
.progothon-faqs {
  width: 100%;
}
@media (max-width: 672px) {
  .progothon-faqs .accordian {
    width: 100%;
  }
}

/*# sourceMappingURL=progothon.css.map */
