.home > * {
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.home > *::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: 25vw;
  background-color: #1d79f2;
  opacity: 0.2;
  filter: blur(220px);
}
.home-hero {
  height: 100vh !important;
  background-color: #111010;
  overflow-y: hidden;
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 880px) {
  .home-hero {
    flex-flow: column;
  }
}
.home-hero > * {
  width: 50%;
  height: 100%;
}
@media (max-width: 880px) {
  .home-hero > * {
    width: 100%;
    height: 50%;
  }
}
.home-hero-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.home-hero-content {
  color: var(--white);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.home-hero-content span {
  width: 70%;
}
.home-hero-content span:first-child {
  margin: 1rem 0;
  font-size: 3.5rem;
  line-height: 4rem;
  font-family: var(--font-bold);
}
.home-hero-content span:nth-child(2) {
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: var(--font-thin);
}
.home-hero-content__buttons {
  margin: 2rem 0;
  border-radius: 12px;
}
.home-hero-content__buttons button {
  margin: 1rem 1.5rem 0 0;
  border-radius: 12px;
}
.home-hero-content-socials {
  position: absolute;
  bottom: 0;
  right: 2rem;
  width: 3rem;
  height: 15rem;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
}
.home-hero-scroll-btn {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 2rem;
  height: 2rem;
  color: var(--white);
  animation: bounce 2s ease-in-out infinite;
}
.home-about {
  background-image: repeating-linear-gradient(to right, var(--bgcolor-700), var(--bgcolor-700) 1px, transparent 1px, transparent);
  background-size: 250px 250px;
  flex-flow: column;
}
.home-about-head {
  width: 80%;
  margin: 3rem auto;
  color: var(--indigo-700);
  text-transform: uppercase;
  font-family: var(--font-bold);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-about-head[data-aos=fade-left] {
  justify-content: flex-end;
}
.home-about-head h1 {
  position: relative;
  text-align: center;
  font-size: 3rem;
  line-height: 3.5rem;
}
.home-about-head-dots {
  position: absolute;
  z-index: -1;
  top: 50%;
  right: 0;
  transform: translate(25%, -50%);
}
.home-about-head[data-aos=fade-left] .home-about-head-dots {
  left: 0;
  transform: translate(-25%, -50%);
}
.home-about-content {
  width: 80%;
  font-size: 2rem;
  line-height: 3rem;
  word-spacing: 0.5rem;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
}
@media (max-width: 672px) {
  .home-about-content {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}
.home-about-content p {
  margin: 1rem 0;
}
.home-about-content-joinimg {
  width: 50vh;
  height: 50vh;
  max-width: fit-content;
  max-height: fit-content;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.home-about-topimg {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 3rem;
  left: 3rem;
}
@media (max-width: 672px) {
  .home-about-topimg {
    display: none;
  }
}
.home-team {
  flex-flow: column;
  height: auto !important;
  min-height: 100vh;
  background-color: #111010;
}
.home-team-head h1 {
  font-size: 3rem;
}
@media (max-width: 672px) {
  .home-team-head h1 {
    font-size: 2.5rem;
  }
}
.home-team-body {
  margin: 3rem;
  width: 100%;
}
.home-progothon {
  height: 100vh;
  background-color: #111010;
  background-blend-mode: darken;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgba(6, 12, 34, 0.9);
  justify-content: flex-start;
  flex-flow: column;
  position: relative;
}
.home-progothon-head {
  padding: 0 3rem;
}
@media (max-width: 672px) {
  .home-progothon-head {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.home-progothon-head h1 {
  font-size: 3rem;
}
@media (max-width: 672px) {
  .home-progothon-head h1 {
    font-size: 2.5rem;
    white-space: nowrap;
  }
}
.home-progothon-body {
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-2rem);
}
.home-progothon-body-container {
  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  top: 0;
  left: 0;
}
@media (max-width: 672px) {
  .home-progothon-body-container {
    height: 75%;
    flex-flow: column;
  }
}
.home-progothon-body-left {
  width: 50%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
@media (max-width: 672px) {
  .home-progothon-body-left {
    width: 100%;
    height: 35%;
  }
}
.home-progothon-body-right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
@media (max-width: 672px) {
  .home-progothon-body-right {
    width: 90%;
    height: 65%;
    padding: 0 1rem;
  }
}
.home-progothon-body-right h2 {
  font-size: 2.5rem;
  line-height: 3rem;
}
.home-progothon-body-right > span {
  font-size: 1.75rem;
  line-height: 2rem;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.25rem);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0.25rem);
  }
  100% {
    transform: translateY(0);
  }
}

/*# sourceMappingURL=home.css.map */
