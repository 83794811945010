.team {
  background-image: repeating-linear-gradient(to right, var(--bgcolor-700), var(--bgcolor-700) 1px, transparent 1px, transparent);
  background-size: 250px 250px;
  position: relative;
}
.team-circles {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: -1;
  background-image: repeating-radial-gradient(circle, transparent 10px, transparent 180px, var(--bgcolor-700) 1px, var(--bgcolor-700) 1px, transparent 182px);
}
.team-circles::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: 25vw;
  background-color: var(--back-shadow);
  opacity: 0.9;
  filter: blur(220px);
}
.team-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--head-height);
  position: relative;
}
.team-hero::before, .team-hero::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: 25vw;
  background-color: #1d79f2;
  opacity: 0.3;
  filter: blur(220px);
  z-index: -1;
}
.team-hero::before {
  left: 25%;
}
.team-hero::after {
  left: 75%;
}
.team-hero-dots {
  position: absolute;
}
@media (max-width: 672px) {
  .team-hero-dots {
    display: none;
  }
}
.team-hero-dots[data-index="1"] {
  top: 20rem;
  left: 5rem;
  width: 3rem;
  height: 3rem;
  transform: rotate(75deg);
}
.team-hero-dots[data-index="2"] {
  top: 10rem;
  right: 3rem;
  width: 2.4rem;
  height: 2.4rem;
  transform: rotate(45deg);
}
.team-hero-ellipse {
  position: absolute;
  top: 70%;
  right: 10%;
}
.team-hero-head {
  width: 100%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.team-hero-head span:first-child {
  font-size: 4rem;
  line-height: 4.5rem;
  font-family: var(--font-bold);
  color: var(--indigo-700);
  margin: 0.75rem 0;
}
.team-hero-head span:last-child {
  width: 75%;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
}
.team-hero-body {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.team-group h2 {
  font-size: 2.5rem;
  line-height: 3rem;
  color: var(--indigo-700);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

/*# sourceMappingURL=team.css.map */
